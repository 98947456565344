import React, {Component} from "react";
import TjdocsApi from "../api/API";
import toast from 'react-hot-toast';
import {Link} from 'react-router-dom';
import { withRouter} from 'react-router-dom';


const Button = require("react-bootstrap/Button")
const Modal = require("react-bootstrap/Modal")
 
const Form = require("react-bootstrap/Form")
const Col = require("react-bootstrap/Col")



  class ExcluirArquivo extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {modal:  false, nomeArquivo: ""  ,descricao: ""};
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
     handleClose(){
         this.setState({ modal: false });
     }
  
     handleShow(e){
        e.preventDefault();
        this.setState({ modal: true });

        this.loadState(this.props.documento)

       

     }

     loadState (documento){

            this.setState({ nomeArquivo: documento.nome })
            this.setState({ descricao: documento.descricao })

            console.log(documento)
     }
 
     handleSubmit() {
                 
        TjdocsApi.excluiDocumento(this.props.documento.id)
        .then( response =>

          toast.success('Sucesso!')
        )
        .then( response =>

				    this.props.history.push('/pastas/'+this.props.documento.idPasta)

        ).catch((error) => {
        	if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        	toast.error(`Erro ao excluir documeto: ${error}`)
        });
 		this.setState({ modal: false });
     }
 
     handleChange (event){
  
        this.setState({[event.target.name]: event.target.value});
         console.log(event.target.value);
     }
  


     render() {
        const { modal } = this.state;
        const { nomeArquivo } = this.state;
        const { descricao } = this.state;


      
        return (
            <>
  
                   <span>
                    <a href="#!" className={this.props.logado  ? "btn btn-danger btn-lg": "d-none"} onClick={this.handleShow}>
                      <i className="fa fa-trash"></i> Excluir
                    </a>
                    &nbsp;
                  </span>

  
  
                     <Modal show={modal} onHide={this.handleClose}  size="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>Excluir Arquivo</Modal.Title>
                        </Modal.Header>
                         <Modal.Body>
                          
                            <Form.Group controlId="1">
                                <div key="nome">
                                    Deseja realmente excluir o arquivo?
                                </div>
                            </Form.Group>
                                      
                         </Modal.Body>
  
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Não
                            </Button>
                            <Button variant="primary" onClick={this.handleSubmit}>
                                Sim
                            </Button>
                        </Modal.Footer>
                    </Modal>
  
                              
             </>
        )
    }
  };
 

 export default withRouter(ExcluirArquivo);