import JWT from "jwt-decode";
import { resolveValue } from "react-hot-toast";

export default class Authentication {  
  
  static url() {
    if(process.env.REACT_APP_TJDOCS2_SERVICE){
    	return process.env.REACT_APP_TJDOCS2_SERVICE;
    }
    
    //return "http://tjdocs2bk-desenvolvimento.apps.tjgo.jus.br";
    return 'http://localhost:8080';
  }

  //solicita o accesstoken ao backend passa informacoes do login corporativo
  static getAccesToken(tokenCorporativo, idSistema, usrCorporativo){
    let urlGetAccessToken = `${this.url()}/oauth/token`
    
    let headers = new Headers(
      {
        'Authorization': 'Basic '+btoa('tjdocs-web:client123'),
        'Content-type':'application/x-www-form-urlencoded',
        'tokencorporativo': tokenCorporativo ,
        'idsistema': idSistema,
        'usrcorporativo': usrCorporativo
      }
    )

    let formBody = [];
    formBody.push("username=corporativo@tjdocs.com");
    formBody.push("password="+tokenCorporativo);
    formBody.push("grant_type=password");
    formBody = formBody.join("&");
    
    return fetch(urlGetAccessToken, {
      body: formBody,
      headers: headers,
      method: "POST",
    })
    .then((resp)=>resp.json())
    .then(data => {return data});    
    
  }

  static checkToken(){

  }

  static isLogin(){
    return sessionStorage.Bearer;
  }

  //função assincrona para pegar o acces_token
  static async login(props){
    
    let tokenCorporativo = (new URLSearchParams(props).get("token_corporativo"));
		let idSistema = (new URLSearchParams(props).get("id_sistema"));
    let usrCorporativo = (new URLSearchParams(props).get("usr_corporativo"));
    
    //se existe na url os tokens do corporativo ocorrerá o processo de login
    if(tokenCorporativo && idSistema && usrCorporativo){      
    
      //se os tokens ainda não foram verificados, por consequencia de um primeiro acesso, ocorrerá a validação do login do corporativo pelo backend
      if(!(this.isLogin())){
        sessionStorage.tokenCorporativo = tokenCorporativo;
        sessionStorage.idSistema = idSistema;
        sessionStorage.usrCorporativo = usrCorporativo;
        console.log("usuario ainda nao autenticado");
        let accessToken = await this.getAccesToken(tokenCorporativo, idSistema, usrCorporativo);
        //se o accesstoken foi retornado do backend a página é recarregada para uma nova renderização dos componentes
        if(accessToken.access_token){
          this.storeAuthentication(accessToken);
          window.location.reload();                
        }
        
      }      
    }
    
  }

  static storeAuthentication(accessToken){    

    if (typeof(Storage) !== "undefined") {
			sessionStorage.Bearer = accessToken.access_token;		
      sessionStorage.RefreshToken = accessToken.refresh_token;	
      let infoUsuario = JWT(accessToken.access_token);      
      sessionStorage.infoUsuario = JSON.stringify(infoUsuario);
      this.usuario = JSON.parse(sessionStorage.infoUsuario);			
		}      
  }

  static logout(){
    
  }

  static getUsuario(){
    if (typeof(Storage) !== "undefined") {
			if (typeof(sessionStorage.Bearer) !== "undefined" && sessionStorage.infoUsuario){
				let usuario = JSON.parse(sessionStorage.infoUsuario);			
        return usuario;
			}
		}
    return "";        
  }

}
