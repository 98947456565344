import JWT from "jwt-decode";
import toast from "react-hot-toast";

export default class TjdocsApi {
  static url() {
    
    if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
    
    if(process.env.REACT_APP_TJDOCS2_SERVICE){
    	return process.env.REACT_APP_TJDOCS2_SERVICE;
    }
    
    //return "http://tjdocs2bk-desenvolvimento.apps.tjgo.jus.br";

    return 'http://localhost:8080';
  }

  static urlWOPI() {    
    if(process.env.REACT_APP_TJDOCS2_SERVICE){
    	return process.env.REACT_APP_TJDOCS2_SERVICE.replace('https', 'http');
    }
    
    return 'http://172.28.9.50:8080';
  }


  static urlLibreOffice() {
    
    if(process.env.REACT_APP_TJDOCS2_SERVICE){
    	return "https://collaboraonline.tjgo.jus.br";
    }

    return 'http://localhost:9980';
  }

  static build_link(resource) {
    return `${this.url()}${resource}`;
  }

  static listaDominios() {
    const urlDominios = `${this.url()}/pastas/dominios`;
    
    let header = {headers:{}};
    this.getHeaderAuthorization(header);
    
    return fetch(urlDominios, header).then(
      (responseDominios) =>
        responseDominios.json()
    );
  }

  static listaUltimosArquivos() {
    const urlUltimosArquivos = `${this.url()}/documentos/ultimos`;
    return fetch(urlUltimosArquivos)
      .then((responseUltimosArquivos) =>
        responseUltimosArquivos.json()
      )
      .then((jsonUltimosArquivos) => jsonUltimosArquivos)
      .catch((error) =>
        console.log(
          `Erro ao tentar consultar os últimos arquivos: ${error}`
        )
      );
  }

  static abrePasta(pasta_id, offset) {
      let urlAbrePasta = `${this.url()}/pastas/${pasta_id}/subpastas`;
      
      let header = {headers:{}};
      this.getHeaderAuthorization(header);
      
      return fetch(urlAbrePasta, header).then((resp) =>
        resp.json()
      );
  }

  static abreArquivos(pasta_id, offset) {
      let urlAbrePasta = `${this.url()}/pastas/${pasta_id}/documentos/paginas?page=${offset}&sort=documento.dataCadastro,desc`;

      let header = {headers:{}};
      this.getHeaderAuthorization(header);

      return fetch(urlAbrePasta, header).then((resp) =>
        resp.json()
      );    
  }

  static ordenaDadosArquivos(pasta_id, offset, field, order) {
    let urlAbrePasta = `${this.url()}/pastas/${pasta_id}/documentos/paginas?page=${offset}&sort=${field},${order}`;

    let header = {headers:{}};
    this.getHeaderAuthorization(header);

    return fetch(urlAbrePasta, header).then((resp) =>
      resp.json()
    );    
  }

  static ordenaPasta(pasta_id, offset, field, order) {
    let urlAbrePasta = `${this.url()}/pastas/${pasta_id}/subpastas/ordenar/${field}/${order}`;
    
    let header = {headers:{}};
    this.getHeaderAuthorization(header);
    
    return fetch(urlAbrePasta, header).then((resp) =>
      resp.json()
    );
}

  static listaDominiosOrdenados(field, order) {
    const urlDominios = `${this.url()}/pastas/dominios/ordenar/${field}/${order}`;
    
    let header = {headers:{}};
    this.getHeaderAuthorization(header);
    
    return fetch(urlDominios, header).then(
      (responseDominios) =>
        responseDominios.json()
    );
  }

  static isLogado() {
    if (typeof Storage !== "undefined") {
      if (typeof sessionStorage.Bearer !== "undefined") {
        try {
          var decoded = JWT(sessionStorage.Bearer);
          return true;
        } catch (e) {
          return false;
        }
      }
    }
    return false;
  }

  static abreArquivo(arquivo_id) {
    const urlAbreArquivo = `${this.url()}/documentos/${arquivo_id}`;

    let header = {headers:{}, method: "GET"};
    this.getHeaderAuthorization(header); 

    return fetch(urlAbreArquivo, header).then((resp) =>
      resp.json()
    );
  }

  static extrairTextoDocumentoPDF(arquivo_id) {
    const urlAbreArquivo = `${this.url()}/v1/documentos/${arquivo_id}/extrairTextoDocumentoPDF`;

    let header = {headers:{}, method: "GET"};
    this.getHeaderAuthorization(header); 

    return fetch(urlAbreArquivo, header).then((resp) => {
      
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return "";
    });
  }

  static getCapaArquivo(arquivo_id) {
    const urlCapaArquivo = `${this.url()}/documentos/download/${arquivo_id}?capa=true`;
    return fetch(urlCapaArquivo).then((resp) =>
      resp.blob()
    );
  }

  static pesquisa(tokens, offset) {
    const urlPesquisa = `${this.url()}/documentos/query?tokens=${tokens}&offset=${offset}`;
    return fetch(urlPesquisa).then((resp) =>
      resp.json()
    );
  }

  static getImgURL(arquivo_id) {
    const url_img = `${this.url()}/documentos/download/${arquivo_id}?capa=true`;
    return url_img;
  }

  static async getReleaseURL(id) {
    const url_img = `${this.url()}/release/${id}/download`;
    const resp = await fetch(url_img);
    const json = await resp.json();
    const link = document.createElement('a');
    link.href = json;
    link.download = "Release";
    link.target = "_blank";
    link.click();
  }

  static async getManualDeInstrucaoURL(arquivo_id) {
    const url_img = `${this.url()}/manualdeinstrucao/${arquivo_id}/download`;
    const resp = await fetch(url_img);
    const json = await resp.json();
    const link = document.createElement('a');
    link.href = json;
    link.download = "Manual De Instrucao";
    link.target = "_blank";
    link.click();
  }

  static listaEtiquetas() {
    const urlDominios = `${this.url()}/v1/etiquetas`;
    return fetch(urlDominios,{
      headers: {
        Authorization:
        "Bearer " + sessionStorage.Bearer,
      },
      method: "GET",
    }).then(
      (responseDominios) => {
        //if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        if (responseDominios.ok){
        return responseDominios.json();
        }
      }
    );
  }

  static listaRelease() {
    const urlRelease = `${this.url()}/v1/release/recuperar-release`;
    return fetch(urlRelease).then(
      (responseRelease) => {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        if (responseRelease.ok){
        return responseRelease.json();
        }
      }
    );
  }

  static async criaEtiqueta(nome, cor, tipo) {
    var url = `${this.url()}/v1/etiquetas`;
    var data = { codigoCor: cor, descricao: "descricao", tipo: tipo, titulo: nome };

    const response_criar_etiqueta = await fetch(url, {
      body: JSON.stringify(data),
      headers: {
        dataType: "json",
        "content-type": "application/json",
        Authorization:
        "Bearer " + sessionStorage.Bearer,
      },
      method: "POST",
    });

    const response_servidor = await response_criar_etiqueta.json();  

    if(!response_criar_etiqueta.ok){       
    	throw new Error("Falha ao criar etiqueta: " + response_servidor.title + " - " + response_servidor.detail);
    }

    return response_criar_etiqueta;

  }

  static recuperarOrdenacao(pastaId, tipo) {
    const url = `${this.url()}/v1/ordenacao/${pastaId}/${tipo}`;

    let header = {headers:{}, method: "GET"};
    let retorno = fetch(url, header).then((resp) => {
      return resp != undefined? resp.json(): null;
    }).catch((error) =>
      console.log(
        `Erro ao tentar consultar ordenação: ${error}`
      )
    );
    return retorno != null && retorno != 'undefined' ? retorno : null;
  }

  static async salvarOrdenacao(pastaId, field, order, tipo, nomeColuna) {
    var url = `${this.url()}/v1/ordenacao`;
    var data = { pastaId: pastaId, field: field, order: order, tipo: tipo, nomeColuna: nomeColuna };

    const response_salvar_ordenacao = await fetch(url, {
      body: JSON.stringify(data),
      headers: {
        dataType: "json",
        "content-type": "application/json",
        Authorization:
        "Bearer " + sessionStorage.Bearer,
      },
      method: "POST",
    });

    const response_servidor = await response_salvar_ordenacao.json();  

    if(!response_salvar_ordenacao.ok){       
    	throw new Error("Falha ao salvar Ordenacao: " + response_servidor.title + " - " + response_servidor.detail);
    }

    
    if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
    return response_salvar_ordenacao;

  }

  static async excluiEtiqueta(id) {
    var url = `${this.url()}/v1/etiquetas/${id}`;

    const response_excluir_etiqueta = await fetch(url, {
      headers: {
        Authorization:
        "Bearer " + sessionStorage.Bearer,
      },      
      method: "DELETE",
    });    

    if(!response_excluir_etiqueta.ok){       
      const response_servidor = await response_excluir_etiqueta.json();        
    	throw new Error("Falha ao excluir etiqueta: " + response_servidor.title + " - " + response_servidor.detail);
    }

    return response_excluir_etiqueta;
  }

  static listaFavoritos() {
    const urlDominios = `${this.url()}/v1/favoritos`;
    return fetch(urlDominios).then(
      (responseDominios) =>
        responseDominios.json()
    );
  }

  static criaFavorito(idPasta) {
    var url = `${this.url()}/v1/favoritos`;
    var data = { id: idPasta };
    return fetch(url, {
      body: JSON.stringify(data),
      headers: {
        dataType: "json",
        "content-type": "application/json",
      },
      method: "POST",
      redirect: "follow",
    }).then((resp) => resp.json());
  }mover

  static excluiFavorito(id) {
    var url = `${this.url()}/v1/favoritos/${id}`;
    return fetch(url, {
      method: "DELETE",
    }).then(function (response) {
      if (response.status != 200) throw new Error("Request Failed");
    });
  }

  static existeDuplicidade(arquivo, nomeArquivo, idPasta) {

    const nome = nomeArquivo == "" ? arquivo.name : nomeArquivo;
    var url = `${this.url()}/v1/documentos/existeDuplicidade/${idPasta}/${nome}`;
    return fetch(url, {
      headers: {
        Authorization:
        "Bearer " + sessionStorage.Bearer,
        "content-type": "application/json",
      },
      method: "GET",
    }).then(function (response) {
      if (response.status == 404) return null;
      if (response.status == 200) return response.json();
    });
  }

  static listaUsuarioPasta(usuarioId) {
    const url = `${this.url()}/v1/usuarios/${usuarioId}/pastas`;
    return fetch(url).then((response) =>
      response.json()
    );
  }

  static existeDuplicidadePasta(nomePasta, idPastaPai) {

    var url = `${this.url()}/v1/pastas/existeDuplicidade/${idPastaPai}/${nomePasta}`;
    return fetch(url, {
      headers: {
        Authorization:
        "Bearer " + sessionStorage.Bearer,
        "content-type": "application/json",
      },
      method: "GET",
    }).then(function (response) {
      if (response.status == 404) return null;
      if (response.status == 200) return response.json();
    });
  }

  static listaUsuarioPasta(usuarioId) {
    const url = `${this.url()}/v1/usuarios/${usuarioId}/pastas`;
    return fetch(url).then((response) =>
      response.json()
    );
  }

  static async moverDocumento(idNodo,nodoPai) {

    //adicionar o if para variável pasta

    var url = `${this.url()}/v1/documentos/${idNodo}/mover`;

    var data = {      
      idPasta: nodoPai      
    };


    const response_mover_documento = await fetch(url, {
      body: JSON.stringify(data),
      headers: {
        dataType: "json",
        "content-type": "application/json",
        Authorization:
        "Bearer " + sessionStorage.Bearer,
      },
      method: "PUT",
    });

    const response_servidor = await response_mover_documento.json();  

    if(!response_mover_documento.ok){       
    	throw new Error("Falha ao mover documento: " + response_servidor.title + " - " + response_servidor.detail);
    }

    return response_mover_documento;
  }


  static async moverPasta (idNodo, nodoPai) {
    var url = `${this.url()}/v1/pastas/${idNodo}/mover`;
       
    var data = {      
      pastaPai: nodoPai      
    };


    const response_mover_pasta = await fetch(url, {
      body: JSON.stringify(data),
      headers: {
        dataType: "json",
        "content-type": "application/json",
        Authorization:
        "Bearer " + sessionStorage.Bearer,
      },
      method: "PUT",
    });

    const response_servidor = await response_mover_pasta.json();  

    if(!response_mover_pasta.ok){       
    	throw new Error("Falha ao mover pasta: " + response_servidor.title + " - " + response_servidor.detail);
    }

    return response_mover_pasta;
  }


  static associarUsuarioPasta(usuarioId, pastaId) {
    var url = `${this.url()}/v1/usuarios/${usuarioId}/pastas/${pastaId}`;
    return fetch(url, {
      method: "PUT",
    }).then(function(response) {
      if (response.status != 204) throw new Error("Não associou");
    });
  }

  static desassociarUsuarioPasta(usuarioId, pastaId) {
    var url = `${this.url()}/v1/usuarios/${usuarioId}/pastas/${pastaId}`;
    return fetch(url, {
      method: "DELETE",
    }).then(function(response) {
      if (response.status != 204) throw new Error("Não desassociou");
    });
  }

  static usuarioAssociadoPasta(usuarioId, pastaId) {
    const url = `${this.url()}/v1/usuarios/${usuarioId}/pastas/${pastaId}`;
    return fetch(url).then((response) =>
      response.json()
    );
  }

  static pesquisa(termo) {
    const urlPesquisa = `${this.url()}/v1/consulta?termo=${termo}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaConteudo(termo) {
    const urlPesquisa = `${this.url()}/v1/consulta/conteudo?termo=${termo}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaConteudoPorPasta(termo, idPasta) {
    const urlPesquisa = `${this.url()}/v1/consulta/conteudoPorPasta/${idPasta}?termo=${termo}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }


  static criaDocumentoOld(etiquetas, descricao, idPasta, arquivo, arquivosVinculados) {
    var url = `${this.url()}/v1/documentos`;
    var nome = arquivo.name.split('.').slice(0, -1).join('.');
    var extensao = arquivo.name.split('.').pop();
    let contentType = arquivo.type;
    let idArquivoSalvo = 0;
    var data = {
      descricao: descricao,
      etiquetas: etiquetas,
      vinculos: arquivosVinculados,
      extensao: extensao,
      idPasta: idPasta, 
      idVisibilidade: 1,
      nome: nome, 
      privado: false, 
      resumo: "Teste",
      contentType: contentType
    };

    return fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "POST",
      })
        .then((response) => {
        	if(response.ok){
        		return response.json()
        	}
        	throw new Error('Falha ao salvar arquivo:');
        })
        .then((resp) => { 
          idArquivoSalvo = resp.id;         
          fetch(resp.urlUpload, {
            method: "PUT",
            body: arquivo
          })
            .then((resp) =>{
              console.log(resp.json());
              fetch(`${this.url()}/documentos/${idArquivoSalvo}/upload`, {
                headers: {
                  dataType: "json",
                  "content-type": "application/json",
		          Authorization:
        		  "Bearer " + sessionStorage.Bearer,                  
                },        
                method: "PUT",
                body: JSON.stringify({upload: 'true'})
              })
            }
            )
            .catch((e) => {
              //console.error(e);
            })
        }
        );
  }

  static async editarComArquivo(idArquivo, arquivo, descricao, idVisibilidade, urlUpload, arquivosVinculados, numDecreto, etiquetas, numLei, descricaoDocumento, idPasta ) {


    var url = `${this.url()}/v1/documentos/`+idArquivo;
    var nome = arquivo.name.split(".").slice(0, -1).join('.');
    var extensao = arquivo.name.split(".").pop();
    let contentType = arquivo.type;


    var data = {
      descricao: descricao,
      etiquetas: etiquetas,
      vinculos: arquivosVinculados,
      numDecreto: numDecreto,
      numLei: numLei,
      descricaoDocumento: descricaoDocumento,
      extensao: extensao,
      idPasta: idPasta, 
      idVisibilidade: idVisibilidade,
      nome: nome, 
      privado: false,       
      contentType: contentType
    };

    const response_editar_arquivo = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",     
          Authorization:
          "Bearer " + sessionStorage.Bearer,          
        },
        method: "PUT",
      })
      .catch((e) => {
          throw new Error("Falha ao editar arquivo: " + e);              
      }).then(response =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return response;
        }
      );
      
      const response_editar_arquivo_json = await response_editar_arquivo.json();
      
      if(!response_editar_arquivo.ok){
        throw new Error("Falha ao editar arquivo: " + response_editar_arquivo_json.title);
      }

      const response_put_ceph = await fetch(urlUpload, {
        method: "PUT",
        body: arquivo
      }).catch((e) => {
          throw new Error("Falha ao enviar arquivo para o ceph: " + e);
      });          

      if(!response_put_ceph.ok){ 	
        throw new Error("Falha ao enviar arquivo para o ceph: ");
      }  

      const response_put_confirma = await fetch(`${this.url()}/documentos/${response_editar_arquivo_json.id}/upload`, {
            headers: {
            dataType: "json",
            "content-type": "application/json",
          Authorization:
            "Bearer " + sessionStorage.Bearer,                  
            },        
            method: "PUT",
            body: JSON.stringify({upload: 'true'})
      }).catch((e) => {
        throw new Error("Falha ao informa sucesso no upload: " + e);
      });     

      
      return response_editar_arquivo_json;
  }


  static async criaDocumento(etiquetas, descricao, idPasta, arquivo, arquivosVinculados, idVisibilidade, numDecreto, numLei, descricaoDocumento, nomeArquivo, ano) {
    let url = `${this.url()}/v1/documentos`;
    let nome = nomeArquivo == "" ? arquivo.name.split('.').slice(0, -1).join('.') : nomeArquivo;
    let extensao = nomeArquivo == "" ? arquivo.name : nomeArquivo;
    let contentType = arquivo.type;
    let idArquivoSalvo = 0;


    var data = {
      descricao: descricao,
      etiquetas: etiquetas,
      vinculos: arquivosVinculados,
      numDecreto: numDecreto,
      numLei: numLei,
      descricaoDocumento: descricaoDocumento,
      extensao: extensao.split(".").pop(),
      idPasta: idPasta, 
      idVisibilidade: idVisibilidade,
      nome: nome, 
      ano: ano,
      privado: false,       
      contentType: contentType
    };
    const response_post_documento = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "POST",
      });
    
    
    const response_criar_documento = await response_post_documento.json();   

    if(!response_post_documento.ok){    	
    	throw new Error("Falha ao criar documento: " + response_criar_documento.title + " - " + response_criar_documento.detail);
    }
    
     
    const response_put_ceph = await fetch(response_criar_documento.urlUpload, {
            method: "PUT",
            body: arquivo
          }).catch((e) => {
              this.excluiDocumento(response_criar_documento.id);
              throw new Error("Falha ao enviar arquivo para o ceph: " + e);
          });          

    if(!response_put_ceph.ok){
    	this.excluiDocumento(response_criar_documento.id);    	
    	throw new Error("Falha ao enviar arquivo para o ceph: ");
    }  
    
    const response_put_confirma = await fetch(`${this.url()}/documentos/${response_criar_documento.id}/upload`, {
          headers: {
          dataType: "json",
          "content-type": "application/json",
	      Authorization:
    		  "Bearer " + sessionStorage.Bearer,                  
          },        
          method: "PUT",
          body: JSON.stringify({upload: 'true'})
    }).catch((e) => {
       this.excluiDocumento(response_criar_documento.id);
       throw new Error("Falha ao informa sucesso no upload: " + e);
    });     

    return response_criar_documento;

  }


  static async inserirVinculoDocumento(id, arquivosVinculados) {
    let url = `${this.url()}/v1/documentos/${id}/inserirVinculoDocumento`;

    var data = {
      vinculos: arquivosVinculados
    };
    console.log(data);
    const response_post_documento = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "POST",
      });
    
    
    const response_criar_documento = await response_post_documento.json();      

    return response_criar_documento;

  }


  static async removerVinculoDocumento(id) {
    var url = `${this.url()}/v1/documentos/removerVinculoDocumento/${id}`;
    
    const response_excluir_vinculo = await fetch(url, {
      method: "DELETE",
        headers: {         
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        }                    
    });
  }


  static async criaRelease(numero, titulo, idRelease, dataRelease, descricao, arquivo) {
    let url = `${this.url()}/v1/release`;
    let nome = arquivo.name;
    let contentType = arquivo.type;
    let idArquivoSalvo = 0;

    var data = {
      id: idRelease,
      numVersao: numero,
      nomeVersao: titulo,
      descricao: descricao,
      contentType: contentType,
      dataRelease: dataRelease,
      tituloRelease: nome
    };
    console.log(data);
    const response_post_documento = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "POST",
      });
    
    
    const response_criar_documento = await response_post_documento.json();   

    if(!response_post_documento.ok){    	
    	throw new Error("Falha ao criar documento: " + response_criar_documento.title + " - " + response_criar_documento.detail);
    }
    
     console.log(response_criar_documento);
    const response_put_ceph = await fetch(response_criar_documento.urlUpload, {
            method: "PUT",
            body: arquivo
          }).catch((e) => {
              this.excluiDocumentoRelease(response_criar_documento.idDocumentoRelease);
              throw new Error("Falha ao enviar arquivo para o ceph: " + e);
          });          

    if(!response_put_ceph.ok){
    	this.excluiDocumentoRelease(response_criar_documento.idDocumentoRelease);    	
    	throw new Error("Falha ao enviar arquivo para o ceph: ");
    }  
    
    const response_put_confirma = await fetch(`${this.url()}/release/${response_criar_documento.id}/upload`, {
          headers: {
          dataType: "json",
          "content-type": "application/json",
	      Authorization:
    		  "Bearer " + sessionStorage.Bearer,                  
          },        
          method: "PUT",
          body: JSON.stringify({upload: 'true'})
    }).catch((e) => {
       this.excluiDocumentoRelease(response_criar_documento.idDocumentoRelease);
       throw new Error("Falha ao informa sucesso no upload: " + e);
    });     

    return response_criar_documento;

  }

  static async editaRelease(numero, titulo, idRelease, dataRelease, descricao, arquivo) {
    let url = `${this.url()}/v1/release/${idRelease}`;
    let nome = arquivo.name;
    let contentType = arquivo.type;
    let idArquivoSalvo = 0;

    var data = {
      id: idRelease,
      numVersao: numero,
      nomeVersao: titulo,
      descricao: descricao,
      contentType: contentType,
      dataRelease: dataRelease,
      tituloRelease: nome
    };
    console.log(data);
    const response_put_documento = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "PUT",
      });
    
    
    const response_criar_documento = await response_put_documento.json();   

    if(!response_put_documento.ok){    	
    	throw new Error("Falha ao criar documento: " + response_criar_documento.title + " - " + response_criar_documento.detail);
    }
    
     console.log(response_criar_documento);
    const response_put_ceph = await fetch(response_criar_documento.urlUpload, {
            method: "PUT",
            body: arquivo
          }).catch((e) => {
              this.excluiDocumentoRelease(response_criar_documento.idDocumentoRelease);
              throw new Error("Falha ao enviar arquivo para o ceph: " + e);
          });          

    if(!response_put_ceph.ok){
    	this.excluiDocumentoRelease(response_criar_documento.idDocumentoRelease);    	
    	throw new Error("Falha ao enviar arquivo para o ceph: ");
    }  
    
    const response_put_confirma = await fetch(`${this.url()}/release/${response_criar_documento.id}/upload`, {
          headers: {
          dataType: "json",
          "content-type": "application/json",
	      Authorization:
    		  "Bearer " + sessionStorage.Bearer,                  
          },        
          method: "PUT",
          body: JSON.stringify({upload: 'true'})
    }).catch((e) => {
       this.excluiDocumentoRelease(response_criar_documento.idDocumentoRelease);
       throw new Error("Falha ao informa sucesso no upload: " + e);
    });     

    return response_criar_documento;

  }

  static async criaManualDeInstrucao(titulo, arquivo) {
    let url = `${this.url()}/v1/manualdeinstrucao`;
    let nome = arquivo.name;
    let contentType = arquivo.type;

    var data = {
      nomeVersao: titulo,
      contentType: contentType,
      documento: nome
    };

    const response_post_documento = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "POST",
      });
    
    
    const response_criar_documento = await response_post_documento.json();   

    if(!response_post_documento.ok){    	
    	throw new Error("Falha ao criar documento: " + response_criar_documento.title + " - " + response_criar_documento.detail);
    }
    
     console.log(response_criar_documento);
    const response_put_ceph = await fetch(response_criar_documento.urlUpload, {
            method: "PUT",
            body: arquivo
          }).catch((e) => {
              //this.excluiManualDeInstrucao(response_criar_documento.id);
              throw new Error("Falha ao enviar arquivo para o ceph: " + e);
          });          

    if(!response_put_ceph.ok){
    	//this.excluiManualDeInstrucao(response_criar_documento.id);    	
    	throw new Error("Falha ao enviar arquivo para o ceph: ");
    }  
    
    const response_put_confirma = await fetch(`${this.url()}/release/${response_criar_documento.id}/upload`, {
          headers: {
          dataType: "json",
          "content-type": "application/json",
	      Authorization:
    		  "Bearer " + sessionStorage.Bearer,                  
          },        
          method: "PUT",
          body: JSON.stringify({upload: 'true'})
    }).catch((e) => {
       //this.excluiManualDeInstrucao(response_criar_documento.id);
       throw new Error("Falha ao informa sucesso no upload: " + e);
    });     

    return response_criar_documento;

  }

  static pesquisaArquivosPasta(termo, idPasta) {
    const urlPesquisa = `${this.url()}/v1/consulta/${idPasta}?termo=${termo}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaArquivosAno(ano) {
    ano = ano === undefined ? '' : ano;
    const urlPesquisa = `${this.url()}/v1/consulta/documentoAnoDoc?ano=${ano} `;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaArquivos(termo, ano) {
    console.log(ano);
    ano = ano === undefined ? '' : ano;
    const urlPesquisa = `${this.url()}/v1/consulta/documentoAno?nome=${termo}&ano=${ano}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaArquivosPorPasta(termo, ano, idPasta) {
    console.log(ano);
    ano = ano === undefined ? '' : ano;
    const urlPesquisa = `${this.url()}/v1/consulta/documentoAnoPasta/${idPasta}?nome=${termo}&ano=${ano}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaArquivosPorDecretoLei(decretoLei, ano) {
    ano = ano === undefined ? '' : ano;
    const urlPesquisa = `${this.url()}/v1/consulta/decretoLeiAno?decretoLei=${decretoLei}&ano=${ano}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaArquivosPorDecretoLeiPorPasta(decretoLei, ano, idPasta) {
    ano = ano === undefined ? '' : ano;
    const urlPesquisa = `${this.url()}/v1/consulta/decretoLeiAnoPasta/${idPasta}?decretoLei=${decretoLei}&ano=${ano}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaArquivosPorDescricao(descricao, ano) {
    ano = ano === undefined ? '' : ano;
    const urlPesquisa = `${this.url()}/v1/consulta/descricaoAno?descricao=${descricao}&ano=${ano}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaArquivosPorDescricaoPorPasta(descricao, ano, idPasta) {
    ano = ano === undefined ? '' : ano;
    const urlPesquisa = `${this.url()}/v1/consulta/descricaoAnoPasta/${idPasta}?descricao=${descricao}&ano=${ano}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static pesquisaPesquisaTotalNaPasta(termo, idPasta) {
    const urlPesquisa = `${this.url()}/v1/consulta/pasta?idPasta=${idPasta}&termo=${termo}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }

  static async excluiDocumentoRelease(id) {
    var url = `${this.url()}/release/${id}/documento`;
    
    const response_excluir_documento = await fetch(url, {
      method: "DELETE",
        headers: {         
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        }                    
    });
    
    const response_excluir_documento_json = await response_excluir_documento.text();   
    
    
    if (!response_excluir_documento.ok){ 
    	let response_json = JSON.parse(response_excluir_documento_json); 
      console.log(response_json)  	
     	//throw new Error(response_json.title);
     } 
    
    return response_excluir_documento_json;
     
  }

  static async excluiManualDeInstrucao(id) {
    var url = `${this.url()}/manualdeinstrucao/${id}`;
    
    const response_excluir_documento = await fetch(url, {
      method: "DELETE",
        headers: {         
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        }                    
    });
    
    const response_excluir_documento_json = await response_excluir_documento.text();   
    
    
    if (!response_excluir_documento.ok){ 
    	let response_json = JSON.parse(response_excluir_documento_json); 
      console.log(response_json)  	
     	//throw new Error(response_json.title);
     } 
    
    return response_excluir_documento_json;
     
  }

  static async excluiRelease(id) {
    var url = `${this.url()}/release/${id}`;
    
    const response_excluir_documento = await fetch(url, {
      method: "DELETE",
        headers: {         
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        }                    
    });
    
    const response_excluir_documento_json = await response_excluir_documento.text();   
    
    
    if (!response_excluir_documento.ok){ 
    	let response_json = JSON.parse(response_excluir_documento_json); 
      console.log(response_json)  	
     	//throw new Error(response_json.title);
     } 
    
    return response_excluir_documento_json;
     
  }

  static async excluiDocumento(id) {
    var url = `${this.url()}/documentos/${id}`;
    
    const response_excluir_documento = await fetch(url, {
      method: "DELETE",
        headers: {         
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        }                    
    });
    
    const response_excluir_documento_json = await response_excluir_documento.text();   
    
    
    if (!response_excluir_documento.ok){ 
    	let response_json = JSON.parse(response_excluir_documento_json);   	
     	throw new Error(response_json.title);
     } 
    
    return response_excluir_documento_json;
     
  }
  
    static pesquisaUsuario(termo) {
    const urlPesquisa = `${this.url()}/permissoes/usuarios/${termo}`;
    return fetch(urlPesquisa).then((resp) =>
    {
      if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
      return resp.json();
    }
    );
  }
  
  static async adicionarPermissao(id_usuario, id_pasta, add_del_pasta, add_del_arquivo, add_del_usuario, del_all_arquivo, del_all_pasta, add_arquivos){
      let url = `${this.url()}/permissoes`;      
      let data = {
      	usuarioId : id_usuario,	
        pastaId : id_pasta,		
        adicionarRemoverArquivo : add_del_arquivo,
        adicionarRemoverPasta : add_del_pasta,
        adicionarRemoverUsuarios : add_del_usuario,
        apagarTodosArquivos: del_all_arquivo,
        apagarTodasPastas: del_all_pasta,
        apagarTodasPastas: del_all_pasta,
        adicionarArquivo: add_arquivos
      };

      const response_adicionar_permissao = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "POST",
      });

      const response_adicionar_permissao_json = await response_adicionar_permissao.text();  

      if (!response_adicionar_permissao.ok){ 
        let response_json = JSON.parse(response_adicionar_permissao_json);   	
         throw new Error(response_json.title + " - " + response_json.detail);
       } 
      
      return response_adicionar_permissao_json;
  
  }
  
static atualizarPermissao(id_usuario, id_pasta, add_del_pasta, add_del_arquivo, add_del_usuario, del_all_arquivo, del_all_pasta, idPermissao, add_arquivos){
      let url = `${this.url()}/permissoes/${idPermissao}`;      
      let data = {
      	usuarioId : id_usuario,	
        pastaId : id_pasta,		
        adicionarRemoverArquivo : add_del_arquivo,
        adicionarRemoverPasta : add_del_pasta,
        adicionarRemoverUsuarios : add_del_usuario,
        apagarTodosArquivos: del_all_arquivo,
        apagarTodasPastas: del_all_pasta,
        adicionarArquivo: add_arquivos
      };
      
      return fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,
        },
        method: "PUT",
      });  
  
  }  
    static listaPermissoes(idPasta) {
    const urlPesquisa = `${this.url()}/permissoes/pastas/${idPasta}`;
    return fetch(urlPesquisa).then((resp) =>
      {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        return resp.json();
      }
    );
  }

  static listaPermissoesPorPastasPorIdUsuario(idPasta, idUsuario) {
    const urlPesquisa = `${this.url()}/permissoes/pastas/${idPasta}/usuario/${idUsuario}`;
    return fetch(urlPesquisa).then((resp) =>
      {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        return resp.json();
      }
    ).catch((error) =>
      console.log(
        `Erro ao tentar consultar permissões: ${error}`
      )
    );
  }

  static listaPermissoesPorDominioPastasPorIdUsuario(idPasta, idUsuario) {
    const urlPesquisa = `${this.url()}/permissoes/dominio/pastas/${idPasta}/usuario/${idUsuario}`;
    return fetch(urlPesquisa).then((resp) =>
      {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
        return resp.json();
      }
    ).catch((error) =>
      console.log(
        `Erro ao tentar consultar permissões: ${error}`
      )
    );
  }
  
  static excluirPermissao(idPermissao){
      let url = `${this.url()}/permissoes/${idPermissao}`;
      
      return fetch(url, {
        headers: {          
          "content-type": "application/json",
        },
        method: "DELETE",
      }).then(resp =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return resp;
        }
      );  
  
  }

  static async recuperaDadosPastaPai(pasta_id) {
    const url = `${this.url()}/pastas/buscadadospasta/${pasta_id}`;

    return await fetch(url, {      
      headers: {          
        "content-type": "application/json",
      },
      method: "GET",
    }).then((resp) => { return resp.json()});

  }
  
  static async caminhoCompleto(idPasta) {
    const url = `${this.url()}/pastas/${idPasta}/caminhocompleto`;


    const response_caminho_completo = await fetch(url, {      
      headers: {
        Authorization:
        "Bearer " + sessionStorage.Bearer,
      },
      method: "GET",
    });

    const response_servidor = await response_caminho_completo.text();  

    if (!response_caminho_completo.ok){ 
      let response_json = JSON.parse(response_servidor);   	
       throw new Error(response_json.title + " - " + response_json.detail);
     } 
    
    return response_servidor;

  }


  static criaPasta(idPastaPai, nomeNovaPasta, pastaPublica) {

    console.log(idPastaPai)

    var url = `${this.url()}/v1/pastas/`+idPastaPai+'/subpastas';

    var data = {
      nome: nomeNovaPasta,
      pastaPai: idPastaPai,
      publica: pastaPublica
    };

    return fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,          
        },
        method: "POST",
      }) .catch((e) => {
              console.error(e);
      }).then(resp =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return resp;
        }
      );

  }


  static excluiPasta(idPasta, novoNome) {


    var url = `${this.url()}/v1/pastas/`+idPasta;

    return fetch(url, {
        headers: {
          dataType: "json",
          "content-type": "application/json",
          Authorization:
          "Bearer " + sessionStorage.Bearer,          

        },
        method: "DELETE",
      })
      .catch((e) => {
              console.error(e);
      })
      .then(response =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return response.json();
        }
      );

  }


  static editarPasta(idPasta, novoNome, idPastaPai, pastaPublica) {


    var url = `${this.url()}/v1/pastas/`+idPasta;

    var data = {
      nome: novoNome,
      pastaPai: idPastaPai,
	  publica: pastaPublica
    };

    console.log(data);

    return fetch(url, {

        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
            Authorization:
          "Bearer " + sessionStorage.Bearer,          

        },
        method: "PUT",
      })
      .catch((e) => {
              console.error(e);
      })
      .then(response =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return response.json();
        }
      );

  }

  static editarVisibilidade(idPasta, idVisibilidade) {


    var url = `${this.url()}/v1/pastas/`+idPasta+`/visibilidade`;

    var data = {
      visibilidadeId: idVisibilidade
    };

    console.log(data);

    return fetch(url, {

        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",
            Authorization:
          "Bearer " + sessionStorage.Bearer,          

        },
        method: "PUT",
      })
      .catch((e) => {
              console.error(e);
      })
      .then(response =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return response.json();
        }
      );

  }

  static async buscarPasta(idPasta) {

    var url = `${this.url()}/pastas/`+idPasta;
    
    let header = {headers:{}, method: "GET"};
    this.getHeaderAuthorization(header);    
    
    
    return await fetch(url, header)
      .catch((e) => {
              console.error(e);
      })
      .then(response =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return response != undefined? response.json(): null;
        }
      ).catch((e) => {
        toast.error("Não foi possível buscar as pastas do usuário, tente novamente mais tarde ou tente fazer login novamente!");
      });

  }

  static async buscarQuantidadeItem(idPasta) {

    var url = `${this.url()}/pastas/`+idPasta+'/quantidadeItens';
    
    let header = {headers:{}, method: "GET"};
    this.getHeaderAuthorization(header);    
    
    
    return await fetch(url, header)
      .catch((e) => {
              console.error(e);
      })
      .then(response =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return response != undefined? response.json(): null;
        }
      ).catch((e) => {
        toast.error("Não foi possível buscar a quantidade de itens selecionado");
      });

  }


  static async editarArquivo(idArquivo, novoNome, descricao, idVisibilidade, numDecreto, numLei, descricaoDocumento, ano, arquivosVinculados) {
    var url = `${this.url()}/v1/documentos/`+idArquivo;
    var data = {
      nome: novoNome.split('.').slice(0, -1).join('.') || novoNome,
      descricao: descricao,
      vinculos: arquivosVinculados,
      idVisibilidade: idVisibilidade,
      numDecreto: numDecreto,
      numLei: numLei,
      descricaoDocumento: descricaoDocumento,
      ano: ano,
      extensao: novoNome.split('.').pop()
    };

    const response_editar_arquivo = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          dataType: "json",
          "content-type": "application/json",     
          Authorization:
          "Bearer " + sessionStorage.Bearer,          
        },
        method: "PUT",
      })
      .catch((e) => {
          throw new Error("Falha ao editar arquivo: " + e);              
      }).then(response =>
        {
          if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
          return response;
        }
      );
      
	const response_editar_arquivo_json = await response_editar_arquivo.json();
	
	if(!response_editar_arquivo.ok){
		throw new Error("Falha ao editar arquivo: " + response_editar_arquivo_json.title);
	}
	
	return response_editar_arquivo_json;
  }
  
  static getHeaderAuthorization(header){ 	
  	
  	if(!this.isLogado()){
  		return;
  	}
  	header["headers"]["Authorization"] = "Bearer " + sessionStorage.Bearer;	  	  	
  }
  
}
