import React, {Component} from "react";
import TjdocsApi from "../api/API";
import {get_path} from "../helper/Helper";
import {FileIconLista} from "../components/FileIconLista";
import {FolderIconLista} from "../components/FolderIconLista";
import Authentication from "../Authentication";

const Button = require("react-bootstrap/Button")


export class MiniDocViewLista extends Component {

	constructor(props) {
		super(props);
		this.state = {
			colunaOrdenada: '',
			ordem: '',
			colunaOrdenadaPropAnt: '',
			ordemPropAnt: ''
		};
	}

	ordenarPorColuna(coluna) {
		
		let { colunaOrdenada, ordem } = this.state;
		
		if (coluna === colunaOrdenada) {
		  // Se a mesma coluna for clicada novamente, alterne a ordem
		  this.setState({
			colunaOrdenada: coluna,
			ordem: ordem === 'asc' ? 'desc' : 'asc',
		  });
		  ordem = ordem === 'asc' ? 'desc' : 'asc';
		} else {
		  // Caso contrário, defina a nova coluna e ordem como padrão (ascendente)
		  this.setState({
			colunaOrdenada: coluna,
			ordem: 'asc',
		  });
		  ordem = 'asc';
		}
		
		this.props.ordenaDados(coluna, ordem);
	  }


	 salvarOrdenacao() {
		const { colunaOrdenada, ordem } = this.state;
		this.props.salvarOrdenacao(colunaOrdenada, ordem);
	 } 	  
	
  render() {
	let {colunaOrdenada, ordem } = this.state;
	console.log("ordem", this.props.ordem);
	if(this.state.colunaOrdenada == '' || this.state.colunaOrdenada == undefined || this.props.colunaOrdenada != this.state.colunaOrdenadaPropAnt || this.props.ordem != this.state.ordemPropAnt){
		
		if(this.props.colunaOrdenada != '' && this.props.colunaOrdenada != undefined && this.props.ordem != '' && this.props.ordem != undefined) { 
			this.setState({
				colunaOrdenada: this.props.colunaOrdenada,
				ordem: this.props.ordem,
				colunaOrdenadaPropAnt: this.props.colunaOrdenada,
				ordemPropAnt: this.props.ordem
			});
			console.log("ordena", this.props.colunaOrdenada, this.props.ordem);
			colunaOrdenada = this.props.colunaOrdenada;
			ordem = this.props.ordem;
		}
		else {
			colunaOrdenada = 'dataCadastro';
			ordem = 'desc';
		}
	}
	
	

	/*let {colunaOrdenada, ordem } = this.state.colunaOrdenada == '' ? this.props : this.state;
	colunaOrdenada = colunaOrdenada == '' || colunaOrdenada == undefined ? 'dataCadastro' : colunaOrdenada;
	ordem = ordem == '' || ordem == undefined ? 'desc' : ordem;*/

	var exibicao = "d-none";
	if (typeof(Storage) !== "undefined") {
		if (typeof(localStorage.exibicao) !== "undefined"){
			if(localStorage.exibicao === "lista") exibicao = "";
		}
	}
	
	return <div id="P-DisplayLista" className={exibicao}>
		<span style={{color: "#0057b5", fontSize: "20px"}}>Para acesso ao sistema, deve-se solicitar o cadastro ao gestor da pasta (decreto N° 2117/2013)</span>
		<Button variant="primary" style={{margin: "5px", float: "right"}} className={Authentication.isLogin() ? "" : "d-none" } onClick={() => { this.salvarOrdenacao() }} >
						<i class="fas fa-save"></i>&nbsp; Salvar Ordenação
                            </Button>
		<table className="table  table-sm table-hover" tabIndex="0" summary=" :  Documentos">
			<thead tabIndex="0">
				<tr>
					<th id="p-c-p-l-Tabl-Nome" onClick={() => this.ordenarPorColuna('nome')} style={{ cursor: 'pointer' }} >Nome {colunaOrdenada === 'nome' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i> : <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Lei" onClick={() => this.ordenarPorColuna('numeroLei')} style={{ cursor: 'pointer' }} >Lei {colunaOrdenada === 'numeroLei' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Decreto" onClick={() => this.ordenarPorColuna('numeroDecreto')} style={{ cursor: 'pointer' }} >Decreto {colunaOrdenada === 'numeroDecreto' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Total-Arquivos" onClick={() => this.ordenarPorColuna('totalArquivos')} style={{ cursor: 'pointer' }} >arquivos {colunaOrdenada === 'totalArquivos' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Tamanho-MB" onClick={() => this.ordenarPorColuna('tamanhoMB')} style={{ cursor: 'pointer' }} >Tamanho em (MB) {colunaOrdenada === 'tamanhoMB' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Tipo-Acesso" onClick={() => this.ordenarPorColuna('tipoAcesso')} style={{ cursor: 'pointer' }} >Tipo de acesso {colunaOrdenada === 'tipoAcesso' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Nome-Gestor" onClick={() => this.ordenarPorColuna('nomeGestor')} style={{ cursor: 'pointer' }} >Gestor/Contato {colunaOrdenada === 'nomeGestor' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Data" onClick={() => this.ordenarPorColuna('dataCadastro')} style={{ cursor: 'pointer' }} >Criação {colunaOrdenada === 'dataCadastro' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
					<th id="p-c-p-l-Tabl-Tamanho" onClick={() => this.ordenarPorColuna('dataAtualizacao')} style={{ cursor: 'pointer' }} >Atualização {colunaOrdenada === 'dataAtualizacao' ? <i class={(ordem === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down')}></i>: <i class="fas fa-sort"></i>}</th>
				</tr>
			</thead>
			<tbody>
				{this.props.children}
			</tbody>
		</table>
	</div>;
  }
}
