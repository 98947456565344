import React, { Component } from "react";
import UsuarioPastaService from "../api/service/usuarioPastaService";
import toast from "react-hot-toast";
import TjdocsApi from '../api/API';
import Authentication from "../Authentication";
import { ListaRelease } from "./ListaRelease";
import { Manual } from "./Manual";

export class BoxGestao extends Component {
  constructor(props) {
		super(props);

		this.state = {
			pastas: [],
			exibeListaRelease: false,
      exibeEditarRelease : false,
      exibeCadastraRelease: false,
			exibeCadastraManual: false,
      releaseSelecionado : null,
      listaRelease: []
		};

    	this.usuarioPastaService = new UsuarioPastaService();

    	this.usuario = Authentication.getUsuario();	
		this.isLogin = Authentication.isLogin();
		this.handleExibeListaRelease = this.handleExibeListaRelease.bind(this);
		this.handleCadastraManual = this.handleCadastraManual.bind(this);
    this.handleCadastraRelease = this.handleCadastraRelease.bind(this);
    this.handleEditarRelease = this.handleEditarRelease.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentWillMount() {
    var usuarioId = this.usuario.usuario_id_tjdocs;
	if (usuarioId !== undefined){
    this.usuarioPastaService
      .listar(usuarioId)
      .then
        (
          (response) => {          
          	  if(typeof(response.data._embedded) != "undefined"){          	  
              const pastas = response.data._embedded.pastas;
              
              if (pastas.length < 1) {
                toast.error(`Não encontrou pastas do usuário`);
                return false
              }
              this.setState({ pastas: pastas });              
            
              this.state.pastas.map((pasta, index) => {
                var element = document.getElementById("ElementoFavorito" + index);
                if ( element != undefined) {
                  element.style.backgroundColor = pasta.nome;
                  element.href = "/pastas/" + pasta.id;
                }
              });
          }}
        )
        .catch(err => {
          toast.error(`Erro ao tentar abrir pastas do usuário: ${err}`);
        });
    }
  }

  handleExibeListaRelease = () => {
    TjdocsApi.listaRelease().then(releases => {
        this.setState({listaRelease : releases._embedded.documentos})
      }
    ).catch(error => toast.error(`Erro ao tentar consultar os domínios: ${error}`));

      if(this.state.exibeListaRelease) {
        this.setState({exibeListaRelease: false});
        return;
      }

      this.setState({exibeListaRelease: true});

  }

  handleDownload = () => {
    TjdocsApi.getManualDeInstrucaoURL(1);
  }

  handleCadastraManual = () => {
      if(this.state.exibeCadastraManual) {
        this.setState({exibeCadastraManual: false});
        return;
      }

      this.setState({exibeCadastraManual: true});

  }

  handleCadastraRelease = () => {
    console.log(this.state.exibeCadastraRelease);
    this.setState({exibeCadastraRelease : this.state.exibeCadastraRelease ? false : true});
  }
 
 handleEditarRelease = (e, release) => {
    this.setState({releaseSelecionado : release})
    this.setState({exibeEditarRelease : this.state.exibeEditarRelease ? false : true });
 }

  

  render() {
    const checkClick = () => {
      var ul = document.getElementById("ListaGestao");
      ul.onclick = function (event) {
        var target = event.target;
        //alert(event.target.innerHTML);

        var text = event.target.innerHTML;
        var regex = /[0-9]+(?=")/;

        var match = regex.exec(text);
        //alert(match);
        //console.log(match[0]);
        //console.log(regex.lastIndex);
      };
    };

    return (
      <div  className="box box-danger" >
        <div className="box-header with-border box-azulEscuro">
          <h3 className="box-title">
            <a style={{color: "#fff"}}
              role="button"
              data-toggle="collapse"
              href="#boxGestao"
              aria-expanded="true"
              aria-controls="boxGestao"
            >
            <i class="fas fa-book" aria-hidden="true"></i>
              &nbsp;Gestão
            </a>
          </h3>
        </div>
        {/* fim box-header */}

        <div
          id="boxGestao"
          className="collapse show"
          data-parent="#boxGestao"
        >
          <div className="box-body no-padding">
            <ul
              className="nav flex-column nav-stacked"
              id={"ListaGestao"}
              key={"ListaGestao"}
            >
              <li
                    className={this.isLogin  ? "nav-item" : "d-none " }
                  >
                    <a
                      className="nav-link d-flex justify-content-between"
                    >
                      Relatório
                    	<span
                        className="favoritos-ico"
                      ></span>
                    </a>
                  </li>
				  <li
                    className={"nav-item "} 
                  >
                    <a  onClick={this.handleDownload} href="javascript:void(0)"
                      className="nav-link d-flex justify-content-between" 
                    >
                      Manual de Instrução
                    	<span
                        className="favoritos-ico"
                      ></span>
                    </a>
                  </li>
				  <li       className={this.isLogin && this.usuario.authorities == 'ADMINISTRADOR'  ? "nav-item" : "d-none "}
                  >
                    <a className="nav-link d-flex justify-content-between" onClick={this.handleCadastraManual} href="javascript:void(0)" >
                      Cadastrar Manual
                    	<span className="favoritos-ico"></span>
                    </a>
                  </li>
				  <li
                    className={"nav-item "}
                  >
                    <a  className="nav-link d-flex justify-content-between" onClick={this.handleExibeListaRelease} href="javascript:void(0)" >
                      Releases
                    	<span className="favoritos-ico"></span>
                    </a>
                    <ListaRelease exibeListaRelease={this.state.exibeListaRelease} closeListaRelease={this.handleExibeListaRelease} listaRelease={this.state.listaRelease} handleCadastraRelease={this.handleCadastraRelease} handleEditarRelease={this.handleEditarRelease} exibeCadastraRelease={this.state.exibeCadastraRelease} exibeEditarRelease={this.state.exibeEditarRelease}  releaseSelecionado={this.state.releaseSelecionado}  />	
                  </li>
            </ul>
			
			<Manual exibeCadastraManual={this.state.exibeCadastraManual} handleCadastraManual={this.handleCadastraManual}  />	
          </div>
          {/* fim box-body */}
        </div>
        {/* fim menu */}
      </div>
    );
  }
}

