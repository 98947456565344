import React, {Component} from "react";
import TjdocsApi from '../api/API';
import toast from 'react-hot-toast';
import chroma from 'chroma-js';

import Authentication from "../Authentication";

const Modal = require("react-bootstrap/Modal")
const Button = require("react-bootstrap/Button") 
const Form = require("react-bootstrap/Form")
const Col = require("react-bootstrap/Col")


export class EditarRelease extends Component {
    constructor(props) {
        super(props);
        this.state = {modal:  this.props.exibeEditarRelease, numero: this.props.releaseSelecionado == null ? null : this.props.releaseSelecionado.numVersao , titulo: this.props.releaseSelecionado == null ? null : this.props.releaseSelecionado.nomeVersao, data: this.props.releaseSelecionado == null ? null : this.formatarData(this.props.releaseSelecionado.dataRelease), descricao: this.props.releaseSelecionado == null ? null : this.props.releaseSelecionado.descricao, arquivo: "", validated: false };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFile = this.handleFile.bind(this);  
        this.handleNumero = this.handleNumero.bind(this);
        this.handleTitulo= this.handleTitulo.bind(this);
        this.handleData= this.handleData.bind(this);
        this.handleDescricao = this.handleDescricao.bind(this);
        this.usuario = Authentication.getUsuario();	
        this.isLogin = Authentication.isLogin();
        
        this.loadState();
        
    }

    loadState (){
      this.setState({ numero: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.numVersao })
      this.setState({ titulo: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.nomeVersao })
      this.setState({ data: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.dataRelease })
      this.setState({ descricao: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.descricao })
   }

    componentWillMount() {    }

    resetState(){
        this.state = {modal:  false, numero: "", titulo: "", data: "", descricao: "", arquivo: "", validated: false };

        this.setState({ numero: null });
        this.setState({ titulo: null });
        this.setState({ data: null });
        this.setState({ descricao: null });
        this.setState({ arquivo: null });

    }
       
    handleClose(){
        this.resetState();
        this.props.handleEditarRelease();
    }

    handleShow(e){        
        this.setState({ numero: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.numVersao })
        this.setState({ titulo: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.nomeVersao })
        this.setState({ data: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.dataRelease })
        this.setState({ descricao: this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.descricao })
        this.setState({ validated: false });

    }

    handleFile(e) {
        e.preventDefault();
        let file = e.target.files[0];

        this.setState({ arquivo: file });
    }
 
   handleSubmit(e) {
       e.preventDefault();
       const form = e.currentTarget;
          
       if (form.checkValidity() === false) {
           e.preventDefault();
           e.stopPropagation();
           this.setState({ validated: true });
 
       } else{           
 
           TjdocsApi.editaRelease(this.state.numero, this.state.titulo, this.props.releaseSelecionado.id, this.state.data, this.state.descricao, this.state.arquivo).then( response =>{
               if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";
               toast.success('Sucesso!');
               }
           ).catch((e) => {
            if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "none";  
            toast.error('Editando Arquivo: ' + e);              
            });
            
           this.handleClose();         
       }
      
   } 

   handleNumero (event){    
      let numero = "";    
      numero = event.target.value;
      
      this.setState({ numero: numero });
   }

   handleTitulo (event){    
      let titulo = "";    
      titulo = event.target.value;
      
      this.setState({ titulo: titulo });
   }

   handleData (event){    
      let data = "";    
      data = event.target.value;
      
      this.setState({ data: data });
   }

   handleDescricao (event){    
      let descricao = "";    
      descricao = event.target.value;
      
      this.setState({ descricao: descricao });
   }

   onChangeFunc = (optionSelected) => {
        if(document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
        window.location.href = optionSelected.value;
    }

    formatarData = (dataString) => {
      if(dataString == null) {
        return '';
      }  

      let data = dataString.split(' ');
      data = data[0].split('/');
      return data[2]+'-'+data[1]+'-'+data[0];
    };  


  render() {
    const numero = this.state.numero != null ? this.state.numero : this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.numVersao;
    const titulo = this.state.titulo != null ? this.state.titulo :this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.nomeVersao;
    const dataFormatada = this.state.data != null ? this.state.data : this.props.releaseSelecionado == null ? "" : this.formatarData(this.props.releaseSelecionado.dataRelease);
    const descricao = this.state.descricao != null ? this.state.descricao : this.props.releaseSelecionado == null ? "" : this.props.releaseSelecionado.descricao;
    const  {validated}  = this.state;
    
      return (
          <>  
                <Modal show={this.props.exibeEditarRelease} onHide={this.handleClose}  autoFocus={false} dialogClassName={"largeModal"}  >
                <Modal.Header style={{backgroundColor: "#004A80"}} >
                    <Modal.Title style={{color: "#ffffff", height: "36px", width: "90%"}}  > 
                      <div style={{fontWeight: "bold", fontSize: "20px", float: "left"}} className="span12 centered-text"> Releases - TJ<span style={{fontWeight: "bold", fontSize: "20px", color : "#0FBDD2"}} >DOCS</span> </div> 
                       
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form id="create-course-form" name = "addForm"
                               noValidate
                               validated={validated}
                               onSubmit={e => this.handleSubmit(e)} >

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <div  style={{marginBottom: "40px", borderBottom: "solid 1px #ccc"}} >
                        <h3 className="span12 centered-text">Release</h3>
                        </div>

                        
                        <Form.Row>
                            <Form.Group as={Col} md="4">
                            <Form.Label>Número:</Form.Label>
                            <div className="mb-7">
                                <input  className="form-control" value={numero} type="text" id="numero"  onChange={e => this.handleNumero(e)} />
                            </div>
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                            <Form.Label>Título:</Form.Label>
                            <div className="mb-7">
                                <input  className="form-control" value={titulo} type="text" id="titulo"   onChange={e => this.handleTitulo(e)} />
                            </div>
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                              <Form.Label>Data:</Form.Label>
                              <div className="mb-7">
                                  <input  className="form-control" value={dataFormatada} type="date" id="data"   onChange={e => this.handleData(e)} />
                              </div>
                            </Form.Group>
                        </Form.Row> 
                        <Form.Row>
                        <Form.Group as={Col} md="12">
                            <Form.Label>Descrição:</Form.Label>
                            <textarea className="form-control" value={descricao} id="descricao" onChange={e => this.handleDescricao(e)} ></textarea>
                        </Form.Group>
                        </Form.Row>         
                        <Form.Row>
                        <Form.Group as={Col} md="12">
                          <div className="mb-3">
                              <input  required className="form-control" type="file" id="formFile" onChange={this.handleFile} />
                          </div>
                        </Form.Group>
                        </Form.Row>
                    </Form.Group>            
                    <div>

                      

                      

                    </div>
                    <Modal.Footer>
                        <div>
                        <Button style={{padding: "10px", color: "#fff", backgroundColor: "#1C7A67", border: "solid 1px #1C7A67", fontWeight: "bold", width: "80px", textAlign: "center", cursor: "pointer", float:"left", marginRight:"5px"}} type="submit" >Editar</Button>
                          <div style={{padding: "10px", color: "#ff0000", border: "solid 1px #ff0000", fontWeight: "bold", width: "80px", textAlign: "center", cursor: "pointer", float:"left"}} onClick={(e) => {this.handleClose();}} >Cancelar</div>
                        </div>
                    </Modal.Footer>
                </Form>              
                </Modal.Body>

            </Modal>
            
          </>
      )
  }
};
 
