import React, {Component} from "react";
import {MiniDocView} from "../components/MiniDocView";
import {MiniDocViewLista} from "../components/MiniDocViewLista";
import TjdocsApi from "../api/API";
import {FolderIcon} from "../components/FolderIcon";
import {FileIcon} from "../components/FileIcon";
import {FolderIconLista} from "../components/FolderIconLista";
import {FileIconLista} from "../components/FileIconLista";
import {MainContainer} from "./MainContainer";
import Authentication from "../Authentication";
import * as PropTypes from "prop-types";
import {get_path} from "../helper/Helper";
import Pagination from "react-js-pagination";
import {MenuDominio} from "../components/MenuDominio";
import {GradePastas} from "../components/GradePastas";
import {GradeArquivos} from "../components/GradeArquivos";
import toast from 'react-hot-toast';


export class FolderContainer extends Component {
    constructor(props) {
        super(props);
		this.podeAdicionarRemoverPasta = false;
       	this.podeAdicionarRemoverArquivo = false;		
       	this.podeAdicionarRemoverUsuario = false;
		this.adicionarArquivo = false;
		this.caminhoCompleto = "";
        this.state = {
            pastas: [],
            documentos: [],
            path: [],
            paginacao:{},
			idPastaPai: 0,
            activePage: 1,
			dadosPastaPai: { },
			colunaOrdenada: "",
			ordem: ""
        };
		this.loadComponent = this.loadComponent.bind(this);
    }
    
    checkPermissao(pasta_id){

    	TjdocsApi.buscarPasta(pasta_id).then( response =>{        	
            if (response == null) return;
			this.setState({ permissao: response.adicionarRemoverPasta })
			this.podeAdicionarRemoverPasta = response.adicionarRemoverPasta;
       		this.podeAdicionarRemoverArquivo = response.adicionarRemoverArquivo;
       		this.podeAdicionarRemoverUsuario = response.adicionarRemoverUsuarios;
			this.podeAdicionarArquivo = response.adicionarArquivo;
            }
        );
		
		TjdocsApi.listaPermissoesPorPastasPorIdUsuario(pasta_id, Authentication.getUsuario().usuario_id_tjdocs).then( response =>{        	
            	if (response == null || response._embedded ==null) return;
				this.setState({ permissaoUsuario: response._embedded.dominioUsuarioOutputList[0] })
			}
        );
    }

	caminhoPastaCompleto(pasta_id){
        TjdocsApi.caminhoCompleto(pasta_id).then(resp =>{   			
            this.caminhoCompleto = resp;
        }).catch((e) => {console.log("erro")});    
    }

	recuperaDadosPastaPai(pasta_id){
        TjdocsApi.recuperaDadosPastaPai(pasta_id).then(resp =>{ 
			console.log("dados pasta pai", resp)  			
            this.state.dadosPastaPai = resp;
        }).catch((e) => {console.log("erro")});    
    }
    

    componentDidMount() {
		this.loadComponent(this.props.match.params.pasta_id)
		
    }

    handlePageChange = (page_number) => {
		
        let offset = (page_number -1);
        let pasta_id = this.props.match.params.pasta_id;
		
		console.log(page_number,pasta_id,offset);

		TjdocsApi.recuperarOrdenacao(pasta_id, 'documento').then(resp => {
			console.log(resp.id);
			if(resp.id === null) {
				TjdocsApi.abrePasta(pasta_id,offset).then(resp => {
					if(resp._embedded){
						if (resp._embedded.pastas[0].id != pasta_id){
							this.setState({
								pastas: resp._embedded.pastas,
								path: resp._embedded.pastas[0].caminhos._embedded.path
							});
						} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
							this.setState({
								pastas: [],
								path: resp._embedded.pastas[0].caminhos._embedded.path
							});
						}
						/*this.setState({                
							pastas: resp._embedded.pastas,                
							path: resp._embedded.pastas[0].caminhos._embedded.path                
						});*/
					}
				})

				TjdocsApi.abreArquivos(pasta_id, offset).then(resp => {			
					if(resp._embedded){
						console.log(resp);
						this.setState({				
							documentos: resp._embedded.documentos,
							paginacao: resp.page,
							activePage: page_number
						});
					}else{

					}
					this.props.handlePath(get_path(this.state.path));
					
				}).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

				this.setState({colunaOrdenada : 'dataCadastro' });
				this.setState({ordem: 'desc' });

			}
			else {
				console.log("ordenar dados", resp);
				this.setState({colunaOrdenada : resp.nomeColuna });
				this.setState({ordem: resp.order});
				this.ordenaDadosArquivosComOffset(resp.nomeColuna, resp.order, offset);
			}
		});

    }

	salvarOrdenacao = (field, order) => {

		let nomeColuna = field;
		let pastaId = this.props.match.params.pasta_id;

		field = (field == 'nome' ? 'documento.nome' : field);
		field = (field == 'dataCadastro' ? 'documento.dataCadastro' : field);
		field = (field == 'dataAtualizacao' ? 'documento.dataAlteracao' : field);
		field = (field == 'tipoAcesso' ? 'documento.visibilidade.nome' : field);
		field = (field == 'nomeGestor' ? 'documento.usuario.nome' : field);
		field = (field == 'tamanhoMB' ? 'documento.tamanho' : field);
		field = (field == 'numeroDecreto' ? 'documento.numDecreto' : field);
		field = (field == 'numeroLei' ? 'documento.numLei' : field);

		TjdocsApi.salvarOrdenacao(pastaId, field, order, "documento", nomeColuna).then(resp => {			
			console.log(resp);
        }).catch(error => console.log(`Erro ao salvar ordenação: ${error}`));

		field = nomeColuna;
		field = (field == 'nome' ? 'ps.nome' : field);
		field = (field == 'dataCadastro' ? 'ps.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'ps.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'ps.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

		TjdocsApi.salvarOrdenacao(pastaId, field, order, "pasta", nomeColuna).then(resp => {			
			console.log(resp);
        }).catch(error => console.log(`Erro ao salvar ordenação: ${error}`));

		toast.success('Sucesso!');
	}

	ordenaDadosArquivos = (fieldBusca, order) => {
		

		let field = fieldBusca; 
		field = (field == 'nome' ? 'documento.nome' : field);
		field = (field == 'dataCadastro' ? 'documento.dataCadastro' : field);
		field = (field == 'dataAtualizacao' ? 'documento.dataAlteracao' : field);
		field = (field == 'tipoAcesso' ? 'documento.visibilidade.nome' : field);
		field = (field == 'nomeGestor' ? 'documento.usuario.nome' : field);
		field = (field == 'tamanhoMB' ? 'documento.tamanho' : field);
		field = (field == 'numeroDecreto' ? 'documento.numDecreto' : field);
		field = (field == 'numeroLei' ? 'documento.numLei' : field);
		field = (field == 'totalArquivos' ? 'documento.dataCadastro' : field);

        let offset = (this.state.paginacao.number);
        let pasta_id = this.props.match.params.pasta_id;

		TjdocsApi.ordenaDadosArquivos(pasta_id, offset, field, order).then(resp => {			
            if(resp._embedded){
				console.log(resp);
				this.setState({				
					documentos: resp._embedded.documentos,
					paginacao: resp.page,
					activePage: offset
				});
			}else{

			}
            this.props.handlePath(get_path(this.state.path));
			
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

		field = fieldBusca; 
		field = (field == 'nome' ? 'ps.nome' : field);
		field = (field == 'dataCadastro' ? 'ps.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'ps.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'ps.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'numeroDecreto' ? 'ps.created_at' : field);
		field = (field == 'numeroLei' ? 'ps.created_at' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

		TjdocsApi.ordenaPasta(pasta_id, offset, field, order).then(resp => {
			if(resp._embedded){
				if (resp._embedded.pastas[0].id != pasta_id){
					this.setState({
						pastas: resp._embedded.pastas,
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});
				} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
					this.setState({
						pastas: [],
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});
				}
			}
			this.props.handlePath(get_path(this.state.path));
			
			{/* Hide no loading */}
			document.querySelector(".loading").style.display = "none";
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

    }

	ordenaDadosArquivosComOffset = (fieldBusca, order, offset) => {
		

		let field = fieldBusca; 
		field = (field == 'nome' ? 'documento.nome' : field);
		field = (field == 'dataCadastro' ? 'documento.dataCadastro' : field);
		field = (field == 'dataAtualizacao' ? 'documento.dataAlteracao' : field);
		field = (field == 'tipoAcesso' ? 'documento.visibilidade.nome' : field);
		field = (field == 'nomeGestor' ? 'documento.usuario.nome' : field);
		field = (field == 'tamanhoMB' ? 'documento.tamanho' : field);
		field = (field == 'numeroDecreto' ? 'documento.numDecreto' : field);
		field = (field == 'numeroLei' ? 'documento.numLei' : field);
		field = (field == 'totalArquivos' ? 'documento.dataCadastro' : field);

        //let offset = (this.state.paginacao.number);
        let pasta_id = this.props.match.params.pasta_id;

		TjdocsApi.ordenaDadosArquivos(pasta_id, offset, field, order).then(resp => {			
            if(resp._embedded){
				console.log(resp);
				this.setState({				
					documentos: resp._embedded.documentos,
					paginacao: resp.page,
					activePage: offset
				});
			}else{

			}
            this.props.handlePath(get_path(this.state.path));
			
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

		field = fieldBusca; 
		field = (field == 'nome' ? 'ps.nome' : field);
		field = (field == 'dataCadastro' ? 'ps.created_at' : field);
		field = (field == 'dataAtualizacao' ? 'ps.updated_at' : field);
		field = (field == 'tipoAcesso' ? 'ps.nome' : field);
		field = (field == 'nomeGestor' ? 'u.username' : field);
		field = (field == 'tamanhoMB' ? 'tamanho' : field);
		field = (field == 'numeroDecreto' ? 'ps.created_at' : field);
		field = (field == 'numeroLei' ? 'ps.created_at' : field);
		field = (field == 'totalArquivos' ? 'totalArquivo' : field);

		TjdocsApi.ordenaPasta(pasta_id, offset, field, order).then(resp => {
			if(resp._embedded){
				if (resp._embedded.pastas[0].id != pasta_id){
					this.setState({
						pastas: resp._embedded.pastas,
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});
				} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
					this.setState({
						pastas: [],
						path: resp._embedded.pastas[0].caminhos._embedded.path
					});
				}
			}
			this.props.handlePath(get_path(this.state.path));
			
			{/* Hide no loading */}
			document.querySelector(".loading").style.display = "none";
        }).catch(error => console.log(`Erro ao abrir pasta: ${error}`));

    }


    componentDidUpdate(prevProps) {
        if(prevProps.match.params.pasta_id !== this.props.match.params.pasta_id )
			this.loadComponent(this.props.match.params.pasta_id);
		
		
    }
    
	loadComponent(pasta_id){		
		
		
		let offset = 0;	
		this.checkPermissao(pasta_id);
		this.caminhoPastaCompleto(pasta_id);
		this.recuperaDadosPastaPai(this.props.match.params.pasta_id);

		TjdocsApi.recuperarOrdenacao(pasta_id, 'documento').then(resp => {
			if(resp != null && resp != 'undefined') {
				if(resp.id === null) {
					TjdocsApi.abrePasta(pasta_id, offset).then(resp => {
						if(resp._embedded){
							if (resp._embedded.pastas[0].id != pasta_id){
								this.setState({
									//documentos: resp._embedded.documentos,
									pastas: resp._embedded.pastas,
									path: resp._embedded.pastas[0].caminhos._embedded.path
								});
							} else { //Atualizar o conteudo para vazio quando n tem mais subpastas
								this.setState({
									pastas: [],
									path: resp._embedded.pastas[0].caminhos._embedded.path
								});
							}
						}
						this.props.handlePath(get_path(this.state.path));
						
						{/* Hide no loading */}
						document.querySelector(".loading").style.display = "none";
					}).catch(error => console.log(`Erro ao abrir pasta: ${error}`));
					
					if(pasta_id !== 0 ){
						this.props.history.push(`/pastas/${pasta_id}`);
						{/*Abre arquivos*/}
						TjdocsApi.abreArquivos(pasta_id, offset).then(resp => {			
							if(resp._embedded){
								this.setState({				
									documentos: resp._embedded.documentos,
									paginacao: resp.page
								});
							}else{
								this.setState({				
									documentos: [],
									paginacao: {}
								});
							}
							this.props.handlePath(get_path(this.state.path));
							
						}).catch(error => console.log(`Erro ao abrir pasta: ${error}`));
					}else{			
						this.props.history.push('/');
						this.setState({
							pastas: [],
							path: null				
						});
						this.props.handlePath(get_path(this.state.path));
						this.props.atualizaDominios();		
			
					}

					this.setState({colunaOrdenada : 'dataCadastro' });
					this.setState({ordem: 'desc' });
				}
				else {
					console.log("ordenar dados", resp);
					this.setState({colunaOrdenada : resp.nomeColuna });
					this.setState({ordem: resp.order});
					this.ordenaDadosArquivos(resp.nomeColuna, resp.order);
				}
			}
		});

        

	}

    renderChildren = (opcao) => {
		if (opcao === "grade"){ //Grade
			console.log("passou grade");
			let pastas = this.state.pastas.map(
				(pasta, i) => 
				<FolderIcon permissoesUsuarios={this.state.permissaoUsuario} listaGestores={pasta.listaGestores} key={i} nome={pasta.nome} id={pasta.id} podeAdicionarRemoverArquivo={pasta.adicionarRemoverArquivo} podeAdicionarArquivo={pasta.adicionarArquivo} podeAdicionarRemoverPasta={pasta.adicionarRemoverPasta} loadComponent={this.loadComponent}  updatedAt={pasta.updatedAt} createdAt={pasta.createdAt} nomeGestor={pasta.nomeGestor} contatoGestor={pasta.contatoGestor} nomeVisibilidade={pasta.visibilidadeNome} />
			);

			let arquivos = this.state.documentos.map((documento, i) => {
					var idPastaPai = 0;
					if (documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2])
						idPastaPai = documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2].id
					return (<FileIcon loadComponent={this.loadComponent} listaGestores={this.state.dadosPastaPai.listaGestores}  permissoesUsuarios={this.state.permissaoUsuario} documento={documento} key={i + pastas.length}  nome={documento.nome} idPastaAtual={this.props.match.params.pasta_id} id={documento.id} dataCadastro={documento.dataCadastro} idPastaPai={idPastaPai} etiquetas={documento.etiquetas}  vinculadores={documento.vinculadores}/>)
				}
			);


			return [<GradePastas pastas={pastas} /> , <GradeArquivos arquivos={arquivos} /> ] ;
		} else { //Lista
			let pastas = this.state.pastas.map(
				(pasta, i) => <FolderIconLista listaGestores={pasta.listaGestores} podeAdicionarRemoverArquivo={pasta.adicionarRemoverArquivo} podeAdicionarArquivo={pasta.adicionarArquivo}  podeAdicionarRemoverPasta={pasta.adicionarRemoverPasta}  permissoesUsuarios={this.state.permissaoUsuario} loadComponent={this.loadComponent}  key={i} nome={pasta.nome} id={pasta.id} updatedAt={pasta.updatedAt} createdAt={pasta.createdAt} nomeGestor={pasta.nomeGestor} contatoGestor={pasta.contatoGestor} totalArquivos={pasta.totalArquivos} tamanho={pasta.tamanho} nomeVisibilidade={pasta.visibilidadeNome}  />
			);
			let arquivos = this.state.documentos.map((documento, i) => {
				let idPastaPai = 0;
				if (documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2])
					idPastaPai = documento.caminhos._embedded.path[documento.caminhos._embedded.path.length-2].id

				return (<FileIconLista listaGestores={this.state.dadosPastaPai.listaGestores} loadComponent={this.loadComponent}  permissoesUsuarios={this.state.permissaoUsuario} key={i + pastas.length} nome={documento.nome} documento={documento} id={documento.id} idPastaAtual={this.props.match.params.pasta_id} dataCadastro={documento.dataCadastro} idPastaPai={idPastaPai} etiquetas={documento.etiquetas} vinculadores={documento.vinculadores}/>)
			}
			);
			return pastas.concat(arquivos);
		}
		
        
    }

    displayPagination = () => {
	  //console.log(this.state)
	  if (document.querySelectorAll(".loading").length > 0)
		document.querySelector(".loading").style.display = "none";
	
	  {if (this.state.paginacao && this.state.paginacao.totalElements > 20) {
        return (<div className="col-md-12">
		{
			<Pagination
			  activeClass="active"
			  activeLinkClass="disabled"
			  activePage={this.state.paginacao.number+1}
			  disabledClass="disabled"
			  firstPageText="<<"
			  innerClass="pagination p-c-b-paginacao"
			  itemClass="page-item p-c-b-pagnc-paginas"
			  itemsCountPerPage={this.state.paginacao.size}
			  lastPageText=">>"
			  linkClass="page-link"
			  nextPageText="&raquo;"
			  onChange={this.handlePageChange}
			  pageRangeDisplayed={14}
			  prevPageText="&laquo;"
			  totalItemsCount={this.state.paginacao.totalElements}			  
			/>
			
		
		}</div>);
      }}
    }

    render() {
        return <div>
			<MenuDominio path={this.state.path}  idPasta={this.props.match.params.pasta_id} podeAdicionarRemoverPasta={this.podeAdicionarRemoverPasta}  
				podeAdicionarRemoverArquivo={this.podeAdicionarRemoverArquivo} 
				podeAdicionarRemoverUsuario={this.podeAdicionarRemoverUsuario}
				podeAdicionarArquivo={this.podeAdicionarArquivo}
				caminhoCompleto={this.caminhoCompleto}
				loadComponent={this.loadComponent}		/>

            <MiniDocView children={this.renderChildren("grade")}/>
			<MiniDocViewLista children={this.renderChildren("lista")} ordenaDados={this.ordenaDadosArquivos} salvarOrdenacao={this.salvarOrdenacao} colunaOrdenada={this.state.colunaOrdenada} ordem={this.state.ordem} />
			
			
			
			
			
            {this.displayPagination()}
        </div>;
    }
}
MainContainer.propTypes = {
     handlePath: PropTypes.func
};
